
    import {defineComponent, reactive, ref, Ref} from 'vue';
    import {BaseColumn, BaseTable, Pagination} from "@/types/table";
    import {useAxios} from "@/plugins/axios";
    import {userStore} from "@/store/user";
    import {usePromise} from "@/components/usePromise";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import {navigation} from "@/pages/profile/profile-menu";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";
    import router from "@/router";

    export interface IRefferal {
        year: number,
        month: number,
        total: number,
        currency: string
    }

    export default defineComponent({
        name: "Referrals",
        components: {
            Submenu,
            MobileMenu,
        },
        setup() {

            const axios = useAxios();

            const notification = useNotification();

            const i18n = useTranslation();

            const table = reactive(new BaseTable(Pagination.None));

            table.addColumn(new BaseColumn('date', 'referrals.period'));
            table.addColumn(new BaseColumn('earnings', 'referrals.earnings'));

            const rows: Ref<Array<IRefferal>> = ref([]);

            const fetch = () => {
                axios.get('earnings/campaign-kickback-fees')
                    .then(response => response.data)
                    .then(response => {
                        rows.value = [];
                        response.kickback_fees.forEach((referral: IRefferal) => rows.value.push(referral));
                    })
                    .catch(() => {
                    })
            }
            fetch();


            const referralModal = reactive(new usePromise());
            const showReferralModal = async () => {
                const confirmed = await referralModal.show();
                if (confirmed) {
                    referralModal.hide();
                }
            }

            const copyUrl = () => {
                const dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = userStore.getState().referral;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                notification.success(i18n.translate('referrals.copied.title'), i18n.translate('referrals.copied.message'))
            }

            return {
                url: userStore.getState().referral,
                locale: userStore.getState().locale,
                table,
                rows,
                showReferralModal,
                referralModal,
                copyUrl,
                navigation,
                router,
            }

        }
    })
