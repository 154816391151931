<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header :name="$translate('auth.user.profile')" previous="Go back to dashboard"
                        @on-previous="router.push({name:'dashboard'})">
                </Header>
            </div>
        </div>
        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-4 md:col-span-3">
                        <div class="bg-white shadow sm:rounded-lg overflow-hidden">
                            <MobileMenu :menu="navigation" />
    <CardHeader :name="$translate('referrals.title')" :description="$translate('referrals.description')"/>
    <DataTable :table="table" :dataset="rows" empty="referrals.empty">
        <template #column_date="{row}">
            {{ $translate(`calendar.months.${row.month}.name`) }} {{ row.year}}
        </template>
        <template #column_earnings="{row}">
            {{ new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: row.currency
            }).format(row.total) }}
        </template>
        <template #image>
            <img src="../../assets/images/empty.svg" class="h-32 m-auto mt-16 mb-8"/>
        </template>
        <template #empty>
            <span class="inline-flex rounded-md shadow-sm">
                <button type="button" @click.prevent="showReferralModal"
                        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    {{ $translate('referrals.share') }}
                </button>
            </span>
        </template>
    </DataTable>
    <CardFooter v-if="rows.length > 0">
        <CardFooterButton :text="$translate('referrals.share')" :primary="false" @click="showReferralModal"/>
    </CardFooter>
    <Modal :modal="referralModal" :hide-footer="true">
        <div class="block w-full">
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-custom-100">
                <i class="fal fa-share-alt text-custom-600"></i>
            </div>
            <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    {{ $translate('referrals.referral.title') }}
                </h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        {{ $translate('referrals.referral.description') }}
                    </p>
                </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div class="mt-1 sm:mt-0">
                    <div class="max-w-lg flex rounded-md shadow-sm cursor-pointer">
                        <input type="text" name="url" id="url" autocomplete="false" v-model="url"
                               class="flex-1 block w-full focus:ring-custom-500 focus:border-custom-500 min-w-0 rounded-none rounded-l-md sm:text-sm border-gray-300">
                        <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                              @click="copyUrl">
                                <i class="fal fa-clone"></i>
                            </span>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 mb-0 sm:mb-3">
                <button type="button"
                        @click="referralModal.hide()"
                        class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-600 text-base font-medium text-white hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500 sm:text-sm">
                    {{ $translate('referrals.close') }}
                </button>
            </div>
        </div>
    </Modal>
                        </div>
                    </div>
                    <Submenu :menu="navigation" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive, ref, Ref} from 'vue';
    import {BaseColumn, BaseTable, Pagination} from "@/types/table";
    import {useAxios} from "@/plugins/axios";
    import {userStore} from "@/store/user";
    import {usePromise} from "@/components/usePromise";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import {navigation} from "@/pages/profile/profile-menu";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";
    import router from "@/router";

    export interface IRefferal {
        year: number,
        month: number,
        total: number,
        currency: string
    }

    export default defineComponent({
        name: "Referrals",
        components: {
            Submenu,
            MobileMenu,
        },
        setup() {

            const axios = useAxios();

            const notification = useNotification();

            const i18n = useTranslation();

            const table = reactive(new BaseTable(Pagination.None));

            table.addColumn(new BaseColumn('date', 'referrals.period'));
            table.addColumn(new BaseColumn('earnings', 'referrals.earnings'));

            const rows: Ref<Array<IRefferal>> = ref([]);

            const fetch = () => {
                axios.get('earnings/campaign-kickback-fees')
                    .then(response => response.data)
                    .then(response => {
                        rows.value = [];
                        response.kickback_fees.forEach((referral: IRefferal) => rows.value.push(referral));
                    })
                    .catch(() => {
                    })
            }
            fetch();


            const referralModal = reactive(new usePromise());
            const showReferralModal = async () => {
                const confirmed = await referralModal.show();
                if (confirmed) {
                    referralModal.hide();
                }
            }

            const copyUrl = () => {
                const dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = userStore.getState().referral;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                notification.success(i18n.translate('referrals.copied.title'), i18n.translate('referrals.copied.message'))
            }

            return {
                url: userStore.getState().referral,
                locale: userStore.getState().locale,
                table,
                rows,
                showReferralModal,
                referralModal,
                copyUrl,
                navigation,
                router,
            }

        }
    })
</script>